import React, {useEffect, useRef, useState} from "react";
// @material-ui/core components
// @material-ui/icons
// import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import classNames from "classnames";
import Footer from "../../components/Footer/Footer";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/profilePage";
import {Redirect} from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import FormControl from "@mui/material/FormControl"

import {DataGrid} from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select";
import Parallax from "../../components/Parallax/Parallax";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import config from "../../config/config.json"
import NavPills from "../../components/NavPills/NavPills";
import TextField from "@material-ui/icons/TextFields";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function SupplierItemPage(props) {
    const classes = useStyles();
    const {...rest} = props;

    const sessionToken = localStorage.getItem("session_token")
    if (!sessionToken) {
        return (<Redirect to="/login"/>)
    }

    const [btnPressed, setBtnPressed] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [addRarityFilterID, setAddRarityFilterID] = useState('0')
    const [addTypeFilterID, setAddTypeFilterID] = useState('0')
    const [addNewItemsFilterID, setAddNewItemsFilterID] = useState('0')
    const [addEthItemsFilterID, setAddEthItemsFilterID] = useState('0')
    const [unidFilterID, setUnidFilterID] = useState('0')
    const [addStatFilterID, setAddStatFilterID] = useState('0')
    const [addStatFilterMin, setAddStatFilterMin] = useState(0)
    const [addStatFilterMax, setAddStatFilterMax] = useState(0)
    const [searchStatFilters, setSearchStatFilters] = useState([]);
    const [searchNewItemsFilters] = useState([
        {
            id: 1,
            name: "New Items only",
            selected: false,
        },
        {
            id: 2,
            name: "Exclude New Items",
            selected: false,
        },
    ]);
    const [searchEthItemsFilters] = useState([
        {
            id: 1,
            name: "Eth Items Only",
            selected: false,
        },
        {
            id: 2,
            name: "Exclude Eth Items",
            selected: false,
        },
    ]);
    const [searchUnidItemsFilters] = useState([
        {
            id: 1,
            name: "Unid Items Only",
            selected: false,
        },
        {
            id: 2,
            name: "Exclude Unid Items",
            selected: false,
        },
    ]);

    const [itemData, setItemData] = useState([]);
    const [loadingItems, setLoadingItems] = useState(true);

    async function downloadSupplierCSV() {
        setBtnPressed(true)
        toast.success("Generating CSV, this may take a while. DO NOT refresh the page! Download starts automatically after the file is ready.")
        let url = config.backend_base_url + 'supplier/csv'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            toast.success("Done!")
            window.location.assign(config.backend_base_url + 'csv/' + response.data)
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    function getItemsSearch() {
        let url = config.backend_base_url + 'items/search'
        axios.post(url, {
            "search_term": searchTerm,
            "rarity_filter_id": parseInt(addRarityFilterID),
            "type_filter_id": parseInt(addTypeFilterID),
            "new_items_filter": parseInt(addNewItemsFilterID),
            "eth_items_filter": parseInt(addEthItemsFilterID),
            "unid_filter": parseInt(unidFilterID),
            "stat_filters": searchStatFilters
        }, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null && response.data.length > 0) {
                toast.success("Search successful [" + response.data.length + "]")
                setItemData(response.data)
            } else {
                toast.error("Search found no matches")
                setItemData([])
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    async function getItemStats() {
        let url = config.backend_base_url + 'stats/all'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null && response.data.length > 0) {
                setItemStats(response.data)
                setAddStatFilterID(response.data[0].id)
            } else {
                setItemStats([])
            }
            setLoadingItems(false)
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    async function getItemRarities() {
        let url = config.backend_base_url + 'rarities/all'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null && response.data.length > 0) {
                setItemRarities(response.data)
            } else {
                setItemRarities([])
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    async function getItemTypes() {
        let url = config.backend_base_url + 'categories/all'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null && response.data.length > 0) {
                setItemTypes(response.data)
            } else {
                setItemTypes([])
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    let defaultMuleRealm = localStorage.getItem("default_realm")
    if (!defaultMuleRealm) {
        defaultMuleRealm = "europe"
    }
    const [realm, setRealm] = useState(defaultMuleRealm);
    const [trashItemData, setTrashItemData] = useState([]);
    const [trashBtnDisabled, setTrashBtnDisabled] = useState(false);
    const [activeTrashItem, setActiveTrashItem] = useState({
        id: 1,
        name: "Item Name",
        image_path: config.backend_base_url + "image/JAH_RUNE.png",
    });

    const [trashGameName, setTrashGameName] = useState("");
    const [trashGamePassword, setTrashGamePassword] = useState("");
    const [mlSelection, setMLSelection] = useState([]);

    let trashCellClick = function (params, event) {
        event.defaultMuiPrevented = true;
        setActiveTrashItem({
            id: params.row.id,
            name: params.row.name,
            stat_description: params.row.stat_description,
            image_path: "https://d2r-member.com/image/" + params.row.image_path,
        })
    }

    let relistSelectedItems = function (itemIDs) {
        axios.post(config.backend_base_url + "trash/remove", {
            "item_ids": itemIDs,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            console.log("Success ========>", response.data);
        }).catch(error => {
            console.log("Error ========>", error);
        })
    }

    async function getTrashItems() {
        let url = config.backend_base_url + 'admin/trash/all'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                if (response.data["items"]) {
                    setTrashItemData(response.data["items"])
                } else {
                    setTrashItemData([])
                    setTrashBtnDisabled(true)
                }
                setTrashGameName(response.data["game_name"])
                setTrashGamePassword(response.data["game_password"])
            } else {
                setTrashItemData([])
                setTrashBtnDisabled(true)
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    let initiateTrashCleanup = function () {
        if (!window.confirm(`This will start your trash session (${trashGameName}/${trashGamePassword}/${realm}) and cannot be stopped. Continue?`)) {
            return
        }
        setTrashBtnDisabled(true)
        axios.post(config.backend_base_url + "admin/trash/create", {
            "realm": realm,
            "game_name": trashGameName,
            "game_password": trashGamePassword
        }, {headers: {'session-token': sessionToken}}).then(response => {
            console.log("Success ========>", response.data);
            toast.success("Success!")
        }).catch(error => {
            toast.error(error.response.data["Message"])
        })
    }

    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef(null);


    useEffect(() => {
        const updateHeaderHeight = () => {
            if (headerRef.current) {
                const headerComputedStyle = window.getComputedStyle(headerRef.current);
                const headerHeight = headerRef.current.offsetHeight;
                const headerPaddingTop = parseInt(headerComputedStyle.paddingTop, 10);
                const headerPaddingBottom = parseInt(headerComputedStyle.paddingBottom, 10);

                // Calculate total height including padding
                const totalHeight = headerHeight + (headerPaddingTop + headerPaddingBottom) *2;

                setHeaderHeight(totalHeight);
            }
        };

        updateHeaderHeight(); // Initial call to set height

        const intervalId = setInterval(() => {
            updateHeaderHeight();
        }, 1000); // Run every second

        // Stop updating after 10 seconds
        setTimeout(() => {
            clearInterval(intervalId);
        }, 10000);

        window.addEventListener("resize", updateHeaderHeight); // Update height on resize

        return () => {
            clearInterval(intervalId); // Cleanup interval on unmount
            window.removeEventListener("resize", updateHeaderHeight); // Cleanup listener on unmount
        };
    }, []);

    if (loadingItems) {
        getTrashItems();
        getItemsSearch();
        getItemStats();
        getItemRarities();
        getItemTypes();
        setLoadingItems(false)
    }

    const [activeItem, setActiveItem] = useState({
        id: 1,
        name: "Selected Item Name",
        image_path: "https://d2r-member.com/image/" + "JAH_RUNE.png",
        skin_image_path: "https://d2r-member.com/skin/" + "JAH_RUNE.png",
    });

    const [itemStatsFilterModal, setItemStatsFilterModal] = useState(false);
    const [itemStats, setItemStats] = useState([]);
    const [itemRarities, setItemRarities] = useState([]);
    const [itemTypes, setItemTypes] = useState([]);

    const columns = [
        {
            field: "id", headerName: "ID", hide: true
        },
        {
            field: "note", headerName: "Note", hide: true
        },
        {
            field: "min_price", headerName: "Minimum Price", hide: true
        },
        {
            field: "actions", headerName: "Actions", flex: 1, displayName: "Actions", renderCell: renderCellFuncActionList
        },
        {
            field: "name", headerName: "Item Name", flex: 6,
        },
        {
            field: "stats_description", headerName: "Stats", flex: 14,
        },
        {
            field: "unid", headerName: "Unid", flex: 1,
        },
        {
            field: "ethereal", headerName: "Ethereal", flex: 1,
        },
        {
            field: "shareholder_name", headerName: "Owner", flex: 3,
        },
        {
            field: "reseller_name", headerName: "Reseller", flex: 3,
        },
        {
            field: "node_name", headerName: "Node", flex: 3,
        },
        {
            field: "status", headerName: "Status", flex: 3,
        },
    ];

    function addNoteToItem(itID, note) {
        let url = config.backend_base_url + 'note/add'
        if (note === "") {
            return
        }

        if (isNaN(itID)) {
            toast.error("Faulty ID")
            return
        }

        axios.post(url, {
            "item_id": itID,
            "note": note,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.status) {
                toast.success("Added note:" + note)
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error(error.message)
        })
    }

    function addMinPriceToItem(itID, minPrice) {
        let url = config.backend_base_url + 'minprice/add'
        if (minPrice === "") {
            return
        }

        if (isNaN(itID)) {
            toast.error("Faulty ID")
            return
        }

        if (isNaN(minPrice)) {
            toast.error("Price needs to be a valid whole number")
            return
        }

        axios.post(url, {
            "item_id": itID,
            "min_price": minPrice,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.status) {
                toast.success("Added minimum price:" + minPrice)
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error(error.message)
        })
    }

    function renderCellFuncActionList(params) {
        const addNote = (e) => {
            e.stopPropagation(); // don't select this row after clicking

            const resp = window.prompt("Enter the note you want to add to the item")
            if (!resp) {
                return
            }
            const api = params.api;
            const thisRow = {};

            api
                .getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach(
                    (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                );
            addNoteToItem(thisRow.id, resp)
        };
        const addMinPrice = (e) => {
            e.stopPropagation(); // don't select this row after clicking

            const resp = window.prompt("Enter the minimum price you want to add to the item. Currently that price will not be enforced, but it will be visible to sellers")
            if (!resp) {
                return
            }
            const api = params.api;
            const thisRow = {};

            api
                .getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach(
                    (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                );
            addMinPriceToItem(thisRow.id, resp)
        };

        let infoText = "ID: " + params.row.id
        infoText += "<br/>  Note: " + params.row.note
        infoText += "<br/>  Minimum Price: " + params.row.min_price + " fg"

        let listD = <Tooltip title={"Add a note to this item"} arrow>
            <div onClick={addNote}>✍️</div>
        </Tooltip>
        let minPriceD = <Tooltip title={"Add a minimum price to this item"} arrow>
            <div onClick={addMinPrice}>🏷️</div>
        </Tooltip>
        let infoD = <Tooltip
            title={<span dangerouslySetInnerHTML={{ __html: infoText }} />}
            arrow>
            <div>🇮</div>
        </Tooltip>
        const viewD =
            <Tooltip title={"VIEW this item in shop"} arrow>
                <a
                    href={params.row.d2rshopproductlink}
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    onClick={(e) => e.stopPropagation()}
                >
                    👁️‍🗨️
                </a>
            </Tooltip>
        ;
        return <span>
            {infoD}
            {params.row.d2rshopproductlink === "" && listD}
            {minPriceD}
            {params.row.d2rshopproductlink !== "" && viewD}
        </span>;
    }

    function getItemsSearchByEnter(event) {
        if (event.key === "Enter") {
            getItemsSearch();
        }
    }

    let cellClick = function (params, event) {
        event.defaultMuiPrevented = true;
        setActiveItem({
            id: params.row.id,
            name: params.row.name,
            image_path: "https://d2r-member.com/image/" + params.row.image_path,
            skin_image_path: "https://d2r-member.com/skin/" + params.row.skin_image_path,
        })
    }

    let addStatRequirementForSearch = function () {
        let statName = ""

        itemStats.forEach(function (it) {
            if (it.id === addStatFilterID) {
                statName = it.name
            }
        })

        setSearchStatFilters(searchStatFilters.concat({
            id: addStatFilterID,
            name: statName,
            min: parseInt(addStatFilterMin),
            max: parseInt(addStatFilterMax),
        }))
        setAddStatFilterID('')
        setItemStatsFilterModal(false)
    }

    return (
        <div>
            <Header
                color="dark"
                brand="D2RS"
                rightLinks={<HeaderLinks/>}
                fixed
                ref={headerRef}
                {...rest}
            />
            <Parallax small filter headerHeight={headerHeight}
            />

            <div className={classNames(classes.main, classes.mainRaised)}>
                <Dialog
                    disableEnforceFocus
                    classes={{
                        root: classes.center,
                        paper: classes.modal,
                    }}
                    open={itemStatsFilterModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setItemStatsFilterModal(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => setItemStatsFilterModal(false)}
                        >
                            <Close className={classes.modalClose}/>
                        </IconButton>
                        <h4 className={classes.modalTitle}>Item Stats Filter</h4>
                    </DialogTitle>
                    <DialogContent
                        id="classic-modal-slide-description"
                        className={classes.modalBody}
                    >
                        <FormControl id="itemStatsFilterForm">
                            <Select
                                id="itemStatFilter"
                                value={addStatFilterID}
                                onChange={(params) => {
                                    setAddStatFilterID(params.target.value)
                                }}
                            >
                                {itemStats.map((stat, key) =>
                                    <MenuItem key={key} value={stat.id}>{stat.name}</MenuItem>
                                )}
                            </Select>
                            <CustomInput
                                labelText="Min Value"
                                id="minValue"
                                value={addStatFilterMin}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                onChange={(params) => {
                                    setAddStatFilterMin(parseInt(params.target.value))
                                }}
                                inputProps={{
                                    type: "number",
                                }}
                                required
                            />
                            <CustomInput
                                labelText="Max Value"
                                id="maxValue"
                                value={addStatFilterMax}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                onChange={(params) => {
                                    setAddStatFilterMax(parseInt(params.target.value))
                                }}
                                inputProps={{
                                    type: "number",
                                }}
                                required
                            />
                            <Button color="primary" type="submit"
                                    form="itemStatsFilterForm" onClick={addStatRequirementForSearch}>
                                Add Stat Filter
                            </Button>
                        </FormControl>
                    </DialogContent>
                </Dialog>

                <div className={classes.container} style={{width: "80vw", position: "relative", maxWidth: "80vw", left: "60%", marginLeft: "-50vw", textAlign: "center"}}>
                    <NavPills
                        queryKey={"t"}
                     alignCenter
                     fullWidth
                     onChange={function () {

                     }}
                     color="primary"
                     tabs={[
                         {
                             tabName: "items",
                             tabButton: "Supplied Items Search (" + itemData.length + ")",
                             tabContent: (
                              <GridContainer spacing={1}>
                                  <GridItem xs={12} sm={12} md={2} xl={2}>
                                      <Button
                                       color="primary"
                                       fullWidth={true}
                                       onClick={() => setItemStatsFilterModal(true)}
                                      >
                                          Add Stat Filter
                                      </Button>
                                  </GridItem>
                                  <GridItem md={2} xl={2}>
                                      <Select
                                       id="newItemsFilter"
                                       fullWidth={true}
                                       value={addNewItemsFilterID}
                                       onChange={(params) => {
                                           setAddNewItemsFilterID(params.target.value)
                                       }}
                                      >
                                          <MenuItem value={'0'}><b>New Item
                                              Filter</b></MenuItem>
                                          {searchNewItemsFilters.map((boolFilter, key) =>
                                           <MenuItem key={key} value={boolFilter.id}>{boolFilter.name}</MenuItem>
                                          )}
                                      </Select>
                                  </GridItem>
                                  <GridItem md={2} xl={2}>
                                      <Select
                                       id="unidItemsFilter"
                                       value={unidFilterID}
                                       fullWidth={true}
                                       onChange={(params) => {
                                           setUnidFilterID(params.target.value)
                                       }}
                                      >
                                          <MenuItem value={'0'}><b>Unid Item
                                              Filter</b></MenuItem>
                                          {searchUnidItemsFilters.map((boolFilter, key) =>
                                           <MenuItem key={key} value={boolFilter.id}>{boolFilter.name}</MenuItem>
                                          )}
                                      </Select>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={2} xl={2}>
                                      <Select
                                       id="ethItemsFilter"
                                       value={addEthItemsFilterID}
                                       fullWidth={true}
                                       onChange={(params) => {
                                           setAddEthItemsFilterID(params.target.value)
                                       }}
                                      >
                                          <MenuItem value={'0'}><b>Eth Item
                                              Filter</b></MenuItem>
                                          {searchEthItemsFilters.map((boolFilter, key) =>
                                           <MenuItem key={key} value={boolFilter.id}>{boolFilter.name}</MenuItem>
                                          )}
                                      </Select>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={2} xl={2}>
                                      <Select
                                       id="itemRarityFilter"
                                       value={addRarityFilterID}
                                       fullWidth={true}
                                       onChange={(params) => {
                                           setAddRarityFilterID(params.target.value)
                                       }}
                                      >
                                          <MenuItem value={'0'}><b>Rarity</b></MenuItem>
                                          {itemRarities.map((rar, key) =>
                                           <MenuItem key={key} value={rar.id}>{rar.name}</MenuItem>
                                          )}
                                      </Select>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={2} xl={2}>
                                      <Select
                                       id="itemTypeFilter"
                                       value={addTypeFilterID}
                                       fullWidth={true}
                                       onChange={(params) => {
                                           setAddTypeFilterID(params.target.value)
                                       }}
                                      >
                                          <MenuItem value={'0'}><b>Type</b></MenuItem>
                                          {itemTypes.map((typ, key) =>
                                           <MenuItem key={key} value={typ.id}>{typ.name}</MenuItem>
                                          )}
                                      </Select>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} xl={12}>
                                      <CustomInput
                                       labelText="Search Term..."
                                       id="searchTerm"
                                       value={searchTerm}
                                       formControlProps={{
                                           fullWidth: true,
                                       }}
                                       onKeyDown={getItemsSearchByEnter}
                                       onChange={(params) => {
                                           setSearchTerm(params.target.value)
                                       }}
                                       inputProps={{
                                           type: "text",
                                           endAdornment: (
                                            <InputAdornment position="end">
                                                <Search className={classes.inputIconsColor}/>
                                            </InputAdornment>
                                           ),
                                           autoComplete: "off",
                                       }}
                                      />
                                  </GridItem>
                                  <GridItem xs={12} sm={12}>
                                      {searchStatFilters.map((filter, key) =>
                                       <Button onClick={() => setSearchStatFilters(searchStatFilters.filter(function (it) {
                                           return it.id !== filter.id
                                       }))} key={key} color="primary" size="sm">
                                           <div style={{contentAlign: "center"}}>
                                               <Close></Close>{filter.name}: {filter.min} - {filter.max}
                                           </div>
                                       </Button>
                                      )}
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} xl={12}>
                                      <Button fullWidth={true} color="primary" type="submit" form="searchParamsForm"
                                              onClick={getItemsSearch}>
                                          Apply Search [{itemData.length}]
                                      </Button>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} xl={12}>
                                      <div style={{height: "100%", width: "100%"}}>
                                          <DataGrid
                                           autoHeight
                                           getRowHeight={() => 'auto'}
                                           getRowWidth={() => 'auto'}
                                           columns={columns}
                                           rows={itemData}
                                           pageSize={10}
                                           rowsPerPageOptions={[10]}
                                           onCellClick={cellClick}
                                          />
                                      </div>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} xl={12}>
                                      <Button color="primary" fullWidth={true} disabled={btnPressed} type="submit" form="searchParamsForm"
                                              onClick={downloadSupplierCSV}>
                                          Download CSV
                                      </Button>
                                      {btnPressed ? (<div>Generating CSV, this may take a while. DO NOT refresh the page! Download starts automatically after the file is ready.</div>):(<div></div>)}
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} xl={12}>
                                      <div>
                                          <b>{activeItem.name}</b>
                                      </div>
                                      <img src={activeItem.skin_image_path}
                                           style={{border: "1px solid black"}}
                                           alt={activeItem.name}/>
                                      <img src={activeItem.image_path}
                                           style={{border: "1px solid black"}}
                                           alt={activeItem.name}/>
                                  </GridItem>
                              </GridContainer>
                             ),
                         },
                         {
                             tabName: "trash",
                             tabButton: "Trash Items (" + trashItemData.length + ")",
                             tabContent: (
                              <GridContainer spacing={1}>
                                  <GridItem xs={12} sm={12} md={12} xl={12}>
                                      <b>You need to create the game before starting the trash session!</b> <br/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} xl={12} >
                                      <CustomInput
                                          labelText="Game Name"
                                          id="gameName"
                                          value={trashGameName}
                                          formControlProps={{
                                              fullWidth: true,
                                          }}
                                          onChange={(params) => {
                                              setTrashGameName(params.target.value)
                                          }}
                                          inputProps={{
                                              type: "text",
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <TextField/>
                                                  </InputAdornment>
                                              ),
                                              autoComplete: "off",
                                          }}
                                          required
                                      />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} xl={12} >
                                      <CustomInput
                                          labelText="Game Password"
                                          id="gamePassword"
                                          value={trashGamePassword}
                                          formControlProps={{
                                              fullWidth: true,
                                          }}
                                          onChange={(params) => {
                                              setTrashGamePassword(params.target.value)
                                          }}
                                          inputProps={{
                                              type: "text",
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <TextField/>
                                                  </InputAdornment>
                                              ),
                                              autoComplete: "off",
                                          }}
                                          required
                                      />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} xl={12} style={{paddingTop: 10}}>
                                      <Select
                                          value={realm}
                                          fullWidth={true}
                                          onChange={(params) => {
                                              setRealm(params.target.value)
                                              localStorage.setItem("default_realm", params.target.value)
                                          }}
                                      >
                                          <MenuItem value={"americas"}>Americas</MenuItem>
                                          <MenuItem value={"europe"}>Europe</MenuItem>
                                          <MenuItem value={"asia"}>Asia</MenuItem>
                                      </Select>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} xl={12}>
                                      <Button
                                       fullWidth={true}
                                       color="primary"
                                       disabled={trashBtnDisabled}
                                       onClick={initiateTrashCleanup}
                                      >
                                          Start Trash Session
                                      </Button>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} xl={12}>
                                      <div style={{height: "100%", width: "100%"}}>
                                          <DataGrid
                                           autoHeight
                                           getRowHeight={() => 'auto'}
                                           getRowWidth={() => 'auto'}
                                           columns={columns}
                                           rows={trashItemData}
                                           pageSize={100}
                                           rowsPerPageOptions={[10]}
                                           onCellClick={trashCellClick}
                                           checkboxSelection
                                           selectionModel={mlSelection}
                                           onSelectionModelChange={(params) => {
                                               if (params.length > 1) {
                                                   toast.error("Please go through your list carefully and only relist 1 item at a time")
                                                   setMLSelection([])
                                                   return
                                               }
                                               console.log(params)

                                               params.forEach(function (item) {
                                                   let selItem = trashItemData.filter(function (selIte) {
                                                       return selIte.id === item
                                                   })[0]
                                                   relistSelectedItems([selItem.id])
                                                   setTrashItemData(trashItemData.filter(function (it) {
                                                       return it.id !== selItem.id
                                                   }));
                                               });
                                           }
                                           }
                                          />
                                      </div>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} xl={12}>
                                      <div>
                                          <b>{activeTrashItem.name}</b>
                                      </div>
                                      <img src={activeTrashItem.image_path}
                                           style={{backgroundSize: "contain", border: "1px solid black"}}
                                           alt={activeTrashItem.name}/>
                                  </GridItem>
                              </GridContainer>
                             ),
                         },
                     ]}
                    />

                </div>
                <Footer/>
            </div>
        </div>
    );
}