import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import Card from "../../components/Card/Card";
import NavPills from "../../components/NavPills/NavPills";
import List from "@material-ui/icons/List";
import {Link} from "react-router-dom";
import Button from "../../components/CustomButtons/Button";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function SellerHelpPage(props) {
    const classes = useStyles();
    const {...rest} = props;
    return (
        <div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand="D2RS"
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white",
                }}
                {...rest}
            />
            <Parallax filter image={require("assets/img/bg7.jpg").default}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <h1>Seller Help Section and F.A.Q.</h1>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className={classes.section}>
                        <div>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card plain>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <NavPills
                                                color="primary"
                                                tabs={[
                                                    {
                                                        tabButton: "Item Page",
                                                        tabIcon: List,
                                                        tabContent: (
                                                            <span>
                        <p>

              <h5>Step 1: Go to the <Link
                  to={"/items"}
                  className={classes.link}>
                        <Button color="primary" size="sm" simple>
                            Item Page
                        </Button>
                    </Link>
              </h5>
                            <h5>Step 2: Choose the Context (Ladder, Hardcore) by toggling the checkboxes in the navigation. You can change the Context whenever you like and the visible Data will be updated if applicable (All Item lists eg)
              </h5>
              <h5>Step 3: All available Items are listed here. When you click on the select box next to an item, it will add it to your cart.
              </h5>
              <h5>Step 4: Select items in your cart to remove them or to initiate a trade after verifying or updating the prices and finalizing the trade.
              </h5>
              <h5>Step 5: Using the saved searches allows you to quickly see stock for items of your choice and to reactivate the equivalent search. To create a saved search, fill out the search parameters and click on the Saved Searches modal, then give the search a name and save it.
              </h5>
              <h5>Step 6: Use the {"'"}Copy FT List from search params to clipboard{"'"} button to create a FT List including all items found with the current search params that will be saved to your clipboard, you can then simply paste it to jsp.
              </h5>
              <h5>Step 7: Use the {"'"}Copy FT List from cart items to clipboard{"'"} button to create a FT List including all items in your cart that will be saved to your clipboard, you can then simply paste it to jsp.
              </h5>
              <h5>Step 8: Use the {"'"}Enable Trash Mode{"'"} toggle to enter trash mode. When you are in trash mode and select an item, it will mark it as trash and move to the suppliers Trash Page. You should do this regularly so we recover mule space ahead of the automatic trashing interval.
              </h5>
                            <h5 color={"blue;"}><b>You have to pay attention to this when dropping: <br/>
                            a) Create the game without any restrictions <br/>
                            b) Dont block the chest as its visibility is needed <br/>
                            c) If the game isnt created, the bot will create it itself <br/>
                            d) If a dropper is stuck for any reason, you can kill it on the Trade Page <br/>
                            e) Don{"'"}t advertise the usage of the dropper on JSP and never post any proof pics in s/a. Accoding to JSP staff its not allowed to droptrade for d2r, so youre instructed to drop to your own games and trade manually

                                <br/>
                            </b>
              </h5>
                        </p>

                                                            <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2>F.A.Q.</h2>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer style={{color: "dimgray"}}>
                    <GridItem xs={12} sm={12} md={4}>
                        <h3 className={classes.description} style={{color: "dimgray"}}>
                            Q
                        </h3>
                        <p className={classes.textCenter}>
                            A
                        </p>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
                      </span>

                                                        ),
                                                    },
                                                    {
                                                        tabButton: "Search Shortcuts",
                                                        tabIcon: List,
                                                        tabContent: (
                                                            <span>
                        <p>
                            <h5 color={"blue;"}><b>There is a couple of things you can do with the input field to search for items faster: <br/>
                            You can use comma separation to search for stats and other filters inline. The value before the first comma will always be the search term. {'"'}search term,included skill1=min, includedskill2=min-max,!excludedskill{'"'}
                                <br/>
use {'"'}.{'"'} infront of search term to get only exactly your search term. Omiting the . will search for anything like the search term as well as types or categories that fit. (Use {'"'}.um rune{'"'} to get um runes, using {'"'}um rune{'"'} would also yield lum runes) <br/>
use {'"'}!{'"'} infront of stat name to exclude this stat (no items having this will show) <br/>
use {'"'}=min-max{'"'} or {'"'}=min{'"'} to use default max <br/>
use {'"'},includedskill{'"'} without = and itll use default min and max <br/>
use {'"'},{'"'} as the first character to search without a search term <br/>
use {'"'}r=rarity_name{'"'} to search for specific rarities <br/>
use {'"'},unid{'"'} to only get unid items <br/>
use {'"'},!unid{'"'} to exclude unid items <br/>
use {'"'},new{'"'} to only get new items <br/>
use {'"'},!new{'"'} to exclude new items <br/>
use {'"'},eth{'"'} to only get eth items <br/>
use {'"'},!eth{'"'} to exclude eth items <br/>
use {'"'} or {'"'} to check for at least one of several stats <br/>
<br/>
search term can be the item name or the item type or the category type <br/>
included skills will be or{'\''}d if several stats match your input, only putting {'"'},life{'"'} will get {'"'}to life{'"'}, {'"'}life replenish{'"'}, {'"'}life per lvl{'"'}, etc <br/>
<br/>
examples:<br/>
ring,faster cast,life ={'>'} all fcr rings with (life or life repl) <br/>
ring,faster cast,mana,!life ={'>'} all fcr rings with mana and no life and no life repl <br/>
ring,faster cast,life=30 ={'>'} all fcr rings with minimum life or life repl +30 <br/>
ring,faster cast,life=30-49 ={'>'} all fcr rings with life or life repl between 30-49 <br/>
,faster cast ={'>'} all items with faster cast rate <br/>
,unid ={'>'} all unid items <br/>
,eth ={'>'} all eth items <br/>
,!eth,r=unique ={'>'} get all non eth unique items <br/>
,faster cast,r=rare ={'>'} get all rare(&craft) items with faster cast rate <br/>
amulet, faster cast, strength or dexte ={'>'} fcr amulets with strength or dexterity <br/>
amulet, faster cast, str or dex ={'>'} fcr amulets with strength or dexterity <br/>
,!life ={'>'} all items without any stat that contains {'"'}life{'"'} <br/>
                                <br/>
                            </b>
              </h5>
                        </p>

                                                            <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2>F.A.Q.</h2>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer style={{color: "dimgray"}}>
                    <GridItem xs={12} sm={12} md={4}>
                        <h3 className={classes.description} style={{color: "dimgray"}}>
                            Q
                        </h3>
                        <p className={classes.textCenter}>
                            A
                        </p>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
                      </span>

                                                        ),
                                                    },
                                                    {
                                                        tabButton: "Dynamic Pickit Page",
                                                        tabIcon: List,
                                                        tabContent: (
                                                            <span>
                        <p>

              <h5>Step 1: Go to the <Link
                  to={"/ml/settings"}
                  className={classes.link}>
                        <Button color="primary" size="sm" simple>
                            Prices Page
                        </Button>
                    </Link>
              </h5>
                            <h5>Step 2: You can bulk update prices using the dynamic pickit entries. The price update will be made for all available items, carted items are not affected.
              </h5>
              <h5>Step 3: When making Dynpickit entries you have to be very precise. All Stats visible on the Stat string must be included. (Required lvl aswell, all of them)
              </h5>
              <h5>Step 4: You can also make large ft lists here, i assure you posting them to jsp will get you lots of interest as your topic will be found prominently with the jsp topic search function
              </h5>
                        </p>

                                                            <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2>F.A.Q.</h2>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer style={{color: "dimgray"}}>
                    <GridItem xs={12} sm={12} md={4}>
                        <h3 className={classes.description} style={{color: "dimgray"}}>
                            Q
                        </h3>
                        <p className={classes.textCenter}>
                            A
                        </p>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
                      </span>

                                                        ),
                                                    },
                                                    {
                                                        tabButton: "Trade Page",
                                                        tabIcon: List,
                                                        tabContent: (
                                                            <span>
                        <p>
              <h5>Step 1: Go to the <Link
                  to={"/rs/trades"}
                  className={classes.link}>
                        <Button color="primary" size="sm" simple>
                            Trades Page
                        </Button>
                    </Link>
              </h5>
                            <h5>Step 2: Select a trade and click on the item to get pictures of the item being hovered and dropped.
              </h5>
              <h5>Step 3: If a drop failed or you need to return the item to the owner, you can click on a drop and follow the instructions to do so. A due_booking entity will be created and booked once the owner approves the return.
              </h5>
              <h5>Step 4: If a game got messed up, you can in the same manner reroute the trade to another game.
              </h5>
              <h5>Step 5: See an overview of the droppers. You can {"'"}KILL{"'"} selected droppers to unstuck them, but do not use this lightly and only if there is a good reason to do so
              </h5>
                        </p>

                                                            <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2>F.A.Q.</h2>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer style={{color: "dimgray"}}>
                    <GridItem xs={12} sm={12} md={4}>
                        <h3 className={classes.description} style={{color: "dimgray"}}>
                            Q
                        </h3>
                        <p className={classes.textCenter}>
                            A
                        </p>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
                      </span>

                                                        ),
                                                    },
                                                    {
                                                        tabButton: "Profile Page",
                                                        tabIcon: List,
                                                        tabContent: (
                                                            <span>
                        <p>
              <h5>Step 1: Go to the <Link
                  to={"/due"}
                  className={classes.link}>
                        <Button color="primary" size="sm" simple>
                            Profile Page
                        </Button>
                    </Link>
              </h5>
                            <h5>Step 2: Here you can see the amount of fg you are due.
              </h5>
              <h5>Step 3: After you send me your due, you can paste the WHOLE goldlog on your profile and click {"'"}Book Due{"'"}, I will be notified and after I accept it its automatically removed from your due and you will see the booking in the list below
              </h5>
              <h5>Step 4: An example of a WHOLE goldlog is this: {"'"}2/28/2023, 11:22:50 AM	Sent	-41,957.00	(190,160.09 -{">"} 148,203.09)	to redacted	(1,182.51 -{">"} 43,139.51)	due{"'"} You have to select the whole line on jsp and copy paste it, dont meddle with it and turn off browser extension if you use any, it might mess up the gold log
              </h5>
              <h5>Step 5: This is my jsp and you only send fg to me https://forums.d2jsp.org/user.php?i=614703

              </h5>
                        </p>

                                                            <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2>F.A.Q.</h2>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer style={{color: "dimgray"}}>
                    <GridItem xs={12} sm={12} md={4}>
                        <h3 className={classes.description} style={{color: "dimgray"}}>
                            Q
                        </h3>
                        <p className={classes.textCenter}>
                            A
                        </p>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
                      </span>

                                                        ),
                                                    },
                                                    {
                                                        tabButton: "Chorescore & HR Dispension",
                                                        tabIcon: List,
                                                        tabContent: (
                                                            <span>
                        <p>
              <h5>Some items like High Runes and Unid Griffons are reserved for fiat transaction and not visible through the normal search. Once per day, all such items that werent sold within a day will be automatically dispensed directly to your carts depending on your score in relation to the total score. Score is the total of 2 things:<br/> </h5>
              <h5>1. Your Trade history of the last day<br/> </h5>
              <h5>2. Your chorescore<br/> </h5>
              <h5>Each sold item (except runes and rares) in the last 24 hours count as 1 point to your score<br/> </h5>
              <h5>Each chorescore of the last 24 hours counts as 3 points to your score<br/> </h5>
              <h5>Chorescore can be earned by doing the following tasks:<br/> </h5>
              <h5>1. Adjust prices using the <Link
                  to={"/ml/settings"}
                  className={classes.link}>
                        <Button color="primary" size="sm" simple>
                            Prices Page
                        </Button>
                    </Link> according to the current market prices on jsp and when shopowners requests price changes on the Discords Price Changes channels (1 Chorescore gained)<br/> </h5>
              <h5>2. Creating Dynamic Pickit entries using the <Link
                  to={"/ml/settings"}
                  className={classes.link}>
                        <Button color="primary" size="sm" simple>
                            Prices Page
                        </Button>
                    </Link> when Shopowners request for unpriced Items to be priced. (2 Chorescore gained)<br/> </h5>
                            <br/>
              <h5>Here is an example of how your score in relation to the total score works:<br/> </h5>
              <h5>There is 100 items to dispense.<br/> </h5>
              <h5>You have a chorescore of 5 and 50 sold items in the last 24 hours. Your score is 50 + (5*3) = 65.<br/> </h5>
              <h5>Systemwide there is a  chorescore of 20 and 200 sold items in the last 24 hours. Total score is 200 + (20*3) = 260.<br/> </h5>
              <h5>In relation you made around 25% of the score, that means you will get 25% of the items that are dispensed in this interval, 25.<br/> </h5>
                        </p>
                                                            <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2>F.A.Q.</h2>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer style={{color: "dimgray"}}>
                    <GridItem xs={12} sm={12} md={4}>
                        <h3 className={classes.description} style={{color: "dimgray"}}>
                            Q
                        </h3>
                        <p className={classes.textCenter}>
                            A
                        </p>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
                      </span>

                                                        ),
                                                    },
                                                ]}
                                            />
                                        </GridItem>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </div>

                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}
