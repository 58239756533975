import React, {useState} from "react";
// @material-ui/core components
// @material-ui/icons
// import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import classNames from "classnames";
import Footer from "../../components/Footer/Footer";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/profilePage";
import {Redirect} from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import config from "../../config/config.json"

import {DataGrid} from "@mui/x-data-grid";
import Parallax from "../../components/Parallax/Parallax";
import NavPills from "../../components/NavPills/NavPills";
import Computer from "@material-ui/icons/Computer";

const useStyles = makeStyles(styles);
export default function DropperPage(props) {
    const classes = useStyles();
    const {...rest} = props;

    const session_token = localStorage.getItem("session_token")
    if (!session_token) {
        return (<Redirect to="/login"/>)
    }

    const [loadingData, setLoadingData] = useState(true);

    if (loadingData) {
        getBotStatus()
        setLoadingData(false)
    }

    const [activeBot, setActiveBot] = useState({
        image_path: "",
        status: "",
        last_ping: ""
    });

    let dropperCellClick = function (params) {
        let url = config.backend_base_url + 'ml/bot/watcher/img/' + params.row.access_key
        axios.get(url, {headers: {'session-token': session_token}}).then(response => {
            if (response.data != null) {
                setActiveBot(response.data)
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    async function getBotStatus() {
        await axios.get(config.backend_base_url + 'bots/all', {headers: {'session-token': session_token}}).then(response => {
            if (response.data != null) {
                setBotStatusData(response.data)
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    const botStatus = [
        {
            field: "last_ping_seconds_ago", headerName: "Last Ping", flex: 1,
        },
        {
            field: "status", headerName: "Status", flex: 2,
        }
    ];
    const [botStatusData, setBotStatusData] = useState([]);

    return (
        <div>
            <Header
                color="dark"
                brand="D2RS"
                rightLinks={<HeaderLinks/>}
                fixed
                {...rest}
            />
            <Parallax small filter
            />

            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className={classNames(classes.title)}>
                        Dropper Overview
                    </div>

                    <NavPills
                        alignCenter
                        color="primary"
                        tabs={[
                            {
                                tabButton: "Dropper Overview",
                                tabIcon: Computer,
                                tabContent: (
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div style={{height: "40em", width: "100%"}}>
                                                <DataGrid
                                                    columns={botStatus}
                                                    rows={botStatusData}
                                                    pageSize={10}
                                                    onCellClick={dropperCellClick}
                                                    rowsPerPageOptions={[10]}
                                                />
                                            </div>
                                            <br/>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} xl={6}>
                                            <img src={"https://d2r-member.com/botwatcher/" + activeBot.image_path}
                                                 style={{border: "1px solid black"}}
                                                 alt={activeBot.status}/>
                                        </GridItem>
                                    </GridContainer>
                                )
                            },
                        ]}
                    />
                    <br/>
                </div>
                <Footer/>
            </div>
        </div>
    );
}