import React from "react";
import PropTypes from "prop-types";

const Parallax = (props) => {
  const { children, style, headerHeight } = props;

  return (
      <div
          style={{
            ...style,
            paddingTop: headerHeight,
          }}
      >
        {children}
      </div>
  );
};

Parallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
  image: PropTypes.string,
  small: PropTypes.bool,
  headerHeight: PropTypes.number.isRequired, // Ensure headerHeight is required and a number
};

export default Parallax;
