import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

const useStyles = makeStyles(styles);

export default function WelcomeSection() {
    const classes = useStyles();

    return (
        <div className={classes.section}>
            <div>
                <h2 className={classes.title}>Terms of Service</h2>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card plain>
                            <CardBody>
                                <p className={classes.description}>
                                    1. I acknowledge that these rules are subject to change and I will enforce them
                                    thereafter
                                </p>
                                <p className={classes.description}>
                                    2. Diablo 2 Resurrected and all implicit trademarks are property of Blizzard
                                </p>
                                <p className={classes.description}>
                                    3. This Software is for educational purposes and offline play only and I will use it
                                    accordingly
                                </p>
                                <p className={classes.description}>
                                    5. Mehlbot staff reserves the right to terminate your service for any reason
                                    whatsoever. In that case your items will be returned to you and all your data will be deleted.
                                </p>
                                <p className={classes.description}>
                                    6. This Software is not to be used for trading on trading platforms until further
                                    notice
                                </p>
                                <p className={classes.description}>
                                    7. The product comes as is. Any future development will be made on our own terms
                                </p>
                                <p className={classes.description}>
                                    8. We do not guarantee any amount of uptime on the system and you will not be compensated for downtime and any resulting loss of value of your items
                                </p>
                                <p className={classes.description}>
                                    9. If your items are on our mules and the accounts get banned we will not reimburse you for the lost items
                                </p>
                                <p className={classes.description}>
                                    10. If your items are otherwise lost in the system you will not be reimbursed
                                </p>
                                <p className={classes.description}>
                                    11. In the case of a dispute regarding the sales price of your item, mehlbot staff solely decides if any form of reimbursement is in order
                                </p>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
            <div>
                <h2 className={classes.title}>Discord Server Rules</h2>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card plain>
                            <CardBody>
                                <p className={classes.description}>
                                    1. I acknowledge that these rules are subject to change and I will enforce them
                                    thereafter
                                </p>
                                <p className={classes.description}>
                                    2. I will treat everyone in the same manner I would like to be treated, with respect
                                </p>
                                <p className={classes.description}>
                                    3. I will not participate in political, ethnical, religious or otherwise
                                    controversial discussion
                                </p>
                                <p className={classes.description}>
                                    4. I will not display toxic behaviour
                                </p>
                                <p className={classes.description}>
                                    5. I will not share explicit or NSFW content
                                </p>
                                <p className={classes.description}>
                                    6. Generally I will use my head before participating in the discord
                                </p>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
