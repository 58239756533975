import React, { useEffect, useRef, forwardRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import d2rsIcon from "../../assets/img/d2rsicon.png";

const useStyles = makeStyles(styles);

const Header = forwardRef((props, ref) => {
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const appBarRef = ref || useRef(null); // Ref for AppBar

    useEffect(() => {
        const headerColorChange = () => {
            const { color, changeColorOnScroll } = props;
            const windowsScrollTop = window.pageYOffset;
            const appBarElement = appBarRef.current;

            if (appBarElement) {
                if (windowsScrollTop > changeColorOnScroll.height) {
                    appBarElement.classList.remove(classes[color]);
                    appBarElement.classList.add(classes[changeColorOnScroll.color]);
                } else {
                    appBarElement.classList.add(classes[color]);
                    appBarElement.classList.remove(classes[changeColorOnScroll.color]);
                }
            }
        };

        if (props.changeColorOnScroll) {
            window.addEventListener("scroll", headerColorChange);
        }

        return () => {
            if (props.changeColorOnScroll) {
                window.removeEventListener("scroll", headerColorChange);
            }
        };
    }, [props.changeColorOnScroll, classes]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes[color]]: color,
        [classes.absolute]: absolute,
        [classes.fixed]: fixed,
    });

    const brandComponent = (
        <Button className={classes.title} href="https://d2rs.io" target="_blank">
            <img style={{ width: "128px" }} src={d2rsIcon} alt={brand} />
        </Button>
    );

    return (
        <AppBar ref={appBarRef} className={appBarClasses}>
            <Toolbar className={classes.container}>
                {leftLinks !== undefined ? brandComponent : null}
                <div className={classes.flex}>
                    {leftLinks !== undefined ? (
                        <Hidden smDown implementation="css">
                            {leftLinks}
                        </Hidden>
                    ) : (
                        brandComponent
                    )}
                </div>
                <Hidden smDown implementation="css">
                    {rightLinks}
                </Hidden>
                <Hidden mdUp>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
            <Hidden mdUp implementation="js">
                <Drawer
                    variant="temporary"
                    anchor={"right"}
                    open={mobileOpen}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    onClose={handleDrawerToggle}
                >
                    <div className={classes.appResponsive}>
                        {leftLinks}
                        {rightLinks}
                    </div>
                </Drawer>
            </Hidden>
        </AppBar>
    );
});

Header.defaultProps = {
    color: "white",
};

Header.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark",
    ]),
    rightLinks: PropTypes.node,
    leftLinks: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark",
        ]).isRequired,
    }),
};

Header.displayName = "Header"; // Setting the display name

export default Header;
