import React, {useEffect, useRef, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons

import config from "../../config/config.json"

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import axios from "axios";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import {Redirect} from "react-router-dom";
import toast from "react-hot-toast";
import FormControl from "@mui/material/FormControl";
import {MenuItem, Select} from "@mui/material";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import {DataGrid} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles(styles);

export default function StaffPage(props) {
    const classes = useStyles();
    const {...rest} = props;

    const sessionToken = localStorage.getItem("session_token")
    if (!sessionToken) {
        return (<Redirect to="/"/>)
    }

    const [loadingData, setLoadingData] = useState(true);
    const nodeOwnersColumns = [
        {
            field: "host_user_id", headerName: "Host User ID", flex: 4,
        },
     {
            field: "id", headerName: "Node ID", flex: 4,
        },
     {
            field: "node_name", headerName: "Node Name", flex: 4,
        },
     {
            field: "monthly_fiat_fee", headerName: "Monthly Fiat Fee", flex: 4,
        },
     {
            field: "is_d2rs_node", headerName: "Is D2RS Node", flex: 4,
        },
     {
            field: "fg_due", headerName: "FG Due Amount", flex: 4,
        },
     {
            field: "fiat_due", headerName: "Fiat Due Amount", flex: 4,
        },
     {
            field: "realm", headerName: "Realm", flex: 4,
        },
        {
            field: "created_at", headerName: "Created At", flex: 3,
        },
        {
            field: "updated_at", headerName: "Updated At", flex: 3,
        },
        {
            field: "status", headerName: "Status", flex: 2,
        },
        {
            field: "make_rares_available_to_d2rshop", headerName: "Rares D2RShop available", flex: 2,
        },
        {
            field: "make_rares_available_to_jsp", headerName: "Rares JSP available", flex: 2,
        },
        {
            field: "make_all_items_available_to_d2rshop", headerName: "All D2RShop available", flex: 2,
        },
        {
            field: "use_own_dynamic_pickits", headerName: "Use Own DynPickits", flex: 2,
        },
        {
            field: "discord_message_id", headerName: "Discord Message ID", flex: 2,
        },
        {
            field: "disabled", headerName: "Disabled", flex: 1,
        }
    ]

    const [nodeNameForNodeCreation, setNodeNameForNodeCreation] = useState("");
    const [allUsers, setAllUsers] = useState([]);
    const [isMehlbotNodeForNodeCreation, setIsMehlbotNodeForNodeCreation] = useState(1);
    const [areRaresShopAvailableForNodeCreation, setAreRaresShopAvailableForNodeCreation] = useState(1);
    const [areRaresJSPAvailableForNodeCreation, setAreRaresJSPAvailableForNodeCreation] = useState(1);
    const [postInSalesHistories, setPostInSalesHistories] = useState(1);
    const [postInNewListings, setPostInNewListings] = useState(1);
    const [isEverythingShopAvailableForNodeCreation, setIsEverythingShopAvailableForNodeCreation] = useState(1);
    const [chosenUserIDForNodeCreation, setChosenUserForNodeCreation] = useState(0);
    const [defaultRealmForNodeCreation, setDefaultRealmForNodeCreation] = useState("europe");
    const [clientTypeForNodeCreation, setClientTypeForNodeCreation] = useState("dll");
    const [monthlyFiatFeeForNodeCreation, setMonthlyFiatFeeForNodeCreation] = useState(0);
    const [discordMessageIDForNodeCreation, setDiscordMessageIDForNodeCreation] = useState("");
    const [nodeOwnersData, setNodeOwnersData] = useState([]);
    const [selectedNodeIDs, setSelectedNodeIDs] = useState([]);

    async function createNewNode() {
        let url = config.backend_base_url + 'staff/node/create'
        await axios.post(url, {
         "node_name": nodeNameForNodeCreation.replaceAll(" ", ""),
         "host_user_id": chosenUserIDForNodeCreation,
         "realm": defaultRealmForNodeCreation,
         "client_type": clientTypeForNodeCreation,
         "is_d2rs_node": isMehlbotNodeForNodeCreation,
         "make_all_items_available_to_d2rshop": isEverythingShopAvailableForNodeCreation,
         "make_rares_available_to_d2rshop": areRaresShopAvailableForNodeCreation,
         "make_rares_available_to_jsp": areRaresJSPAvailableForNodeCreation,
         "monthly_fiat_fee": monthlyFiatFeeForNodeCreation,
         "discord_message_id": discordMessageIDForNodeCreation,
         "show_in_new_listings": postInNewListings,
         "show_in_sales_history": postInSalesHistories,
         "status": "offline",
        }, {headers: {'session-token': sessionToken}}).then(response => {
            getNodeOwnersData()
            toast.success("Node created.")
         console.log(response)
         getNodeOwnersData()
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured.", error)
        })
    }
    async function disableNodeFunc() {
     if (selectedNodeIDs.length !== 1) {
      toast.error("Select one node owner at a time")
      return
     }
        let url = config.backend_base_url + 'staff/node/disable'
        await axios.post(url, {
         "node_id": selectedNodeIDs[0],
        }, {headers: {'session-token': sessionToken}}).then(response => {
            console.log(response)
            toast.success("Node disabled.")
            getNodeOwnersData()
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured.")
        })
    }

    async function getNodeOwnersData() {
        let url = config.backend_base_url + 'staff/nodes'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if ((response.data != null) && (response.data.length > 0)) {
                setNodeOwnersData(response.data)
            } else {
                setNodeOwnersData([])
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }


    async function getProfile() {
        await axios.get(config.backend_base_url + 'profile', {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data["name"] !== "Mehl" && response.data["name"] !== "Mehlich") {
                toast.error("Error occured. Please refresh the page")
                return (<Redirect to="/"/>)
            }
            if (response.data != null) {
                console.log(response.data)
                //todo: do we need this data?
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    async function getUsers() {
        await axios.get(config.backend_base_url + 'node/users/all', {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                setAllUsers(response.data)
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef(null);


    useEffect(() => {
        const updateHeaderHeight = () => {
            if (headerRef.current) {
                const headerComputedStyle = window.getComputedStyle(headerRef.current);
                const headerHeight = headerRef.current.offsetHeight;
                const headerPaddingTop = parseInt(headerComputedStyle.paddingTop, 10);
                const headerPaddingBottom = parseInt(headerComputedStyle.paddingBottom, 10);

                // Calculate total height including padding
                const totalHeight = headerHeight + (headerPaddingTop + headerPaddingBottom) *2;

                setHeaderHeight(totalHeight);
            }
        };

        updateHeaderHeight(); // Initial call to set height

        const intervalId = setInterval(() => {
            updateHeaderHeight();
        }, 1000); // Run every second

        // Stop updating after 10 seconds
        setTimeout(() => {
            clearInterval(intervalId);
        }, 10000);

        window.addEventListener("resize", updateHeaderHeight); // Update height on resize

        return () => {
            clearInterval(intervalId); // Cleanup interval on unmount
            window.removeEventListener("resize", updateHeaderHeight); // Cleanup listener on unmount
        };
    }, []);

    if (loadingData) {
        getProfile();
        getUsers();
     getNodeOwnersData()
        setLoadingData(false)
    }

    return (
        <div>
            <Header
                color="dark"
                brand="D2RS"
                rightLinks={<HeaderLinks/>}
                fixed
                ref={headerRef}
                {...rest}
            />
            <Parallax small filter headerHeight={headerHeight}
            />
            <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container} style={{width: "80vw", position: "relative", maxWidth: "80vw", left: "60%", marginLeft: "-50vw", textAlign: "center"}}>
                     <NavPills
                         queryKey={"t"}
                         alignCenter
                         fullWidth
                         onChange={function () {

                         }}
                         color="primary"
                         tabs={[
                             {
                                 tabName: "nodes",
                                 tabButton: "Node Overview (" + nodeOwnersData.length + ")",
                                 tabContent: (
                                  <GridContainer justify="center">
                                   <GridItem xs={12} sm={12} md={12}>
                                    <FormControl id="adminBookDueForm" fullWidth={true}>
                                        <Autocomplete
                                            id="Stat Filters"
                                            options={allUsers}
                                            getOptionLabel={(option) => option.id + ": "+option.name || ""}
                                            renderInput={(params) => <TextField {...params}
                                                                                label="Search for one of your Node Users"/>}
                                            onChange={(event, option) => {
                                                event.preventDefault()
                                                setChosenUserForNodeCreation(option.id)
                                            }}
                                        />
                                     <Select
                                      id="noderealmas"
                                      value={clientTypeForNodeCreation}
                                      onChange={(params) => {
                                       setClientTypeForNodeCreation(params.target.value)
                                      }}
                                     >
                                      <MenuItem value={0}
                                                selected={true}>{"Choose a client"}</MenuItem>
                                      <MenuItem value={"dll"}>{"dll 💉"}</MenuItem>
                                      <MenuItem value={"pixel️"}>{"pixel 🖼️"}</MenuItem>
                                     </Select>
                                     <Select
                                      id="noderealm"
                                      value={defaultRealmForNodeCreation}
                                      onChange={(params) => {
                                       setDefaultRealmForNodeCreation(params.target.value)
                                      }}
                                     >
                                      <MenuItem value={0}
                                                selected={true}>{"Choose a realm"}</MenuItem>
                                      <MenuItem value={"europe"}>{"europe"}</MenuItem>
                                      <MenuItem value={"americas"}>{"americas"}</MenuItem>
                                      <MenuItem value={"asia"}>{"asia"}</MenuItem>
                                     </Select>
                                     <Select
                                      id="nodeuserid"
                                      value={isMehlbotNodeForNodeCreation}
                                      onChange={(params) => {
                                       setIsMehlbotNodeForNodeCreation(params.target.value)
                                      }}
                                     >
                                      <MenuItem value={0}
                                                selected={true}>{"Is not a Mehlbot Node"}</MenuItem>
                                      <MenuItem value={1}>{"Is a Mehlbot Node"}</MenuItem>
                                     </Select>
                                     <Select
                                      id="nodeuserid"
                                      value={isEverythingShopAvailableForNodeCreation}
                                      onChange={(params) => {
                                       setIsEverythingShopAvailableForNodeCreation(params.target.value)
                                      }}
                                     >
                                      <MenuItem value={0}
                                                selected={true}>{"Everything is NOT Shop available"}</MenuItem>
                                      <MenuItem value={1}>{"Everything is Shop available"}</MenuItem>
                                     </Select>
                                     <Select
                                      id="nodeuserid"
                                      value={areRaresShopAvailableForNodeCreation}
                                      onChange={(params) => {
                                       setAreRaresShopAvailableForNodeCreation(params.target.value)
                                      }}
                                     >
                                      <MenuItem value={0}
                                                selected={true}>{"Rares are NOT Shop available"}</MenuItem>
                                      <MenuItem value={1}>{"Rares are Shop available"}</MenuItem>
                                     </Select>
                                     <Select
                                      id="nodeuserid"
                                      value={areRaresJSPAvailableForNodeCreation}
                                      onChange={(params) => {
                                       setAreRaresJSPAvailableForNodeCreation(params.target.value)
                                      }}
                                     >
                                      <MenuItem value={0}
                                                selected={true}>{"Rares are NOT JSP available"}</MenuItem>
                                      <MenuItem value={1}>{"Rares are JSP available"}</MenuItem>
                                     </Select>
                                     <Select
                                      id="nodeuserid"
                                      value={postInNewListings}
                                      onChange={(params) => {
                                       setPostInNewListings(params.target.value)
                                      }}
                                     >
                                      <MenuItem value={0}
                                                selected={true}>{"Do NOT show new listings on discord channels"}</MenuItem>
                                      <MenuItem value={1}>{"Show new listings on discord channels"}</MenuItem>
                                     </Select>
                                     <Select
                                      id="nodeuserid"
                                      value={postInSalesHistories}
                                      onChange={(params) => {
                                       setPostInSalesHistories(params.target.value)
                                      }}
                                     >
                                      <MenuItem value={0}
                                                selected={true}>{"Do NOT show sales history on discord channels"}</MenuItem>
                                      <MenuItem value={1}>{"Show sales history on discord channels"}</MenuItem>
                                     </Select>
                                        <CustomInput
                                            labelText="Discord Message ID"
                                            id="did"
                                            value={discordMessageIDForNodeCreation}
                                            onChange={(params) => {
                                                setDiscordMessageIDForNodeCreation(params.target.value)
                                            }}
                                            inputProps={{
                                                type: "text",
                                            }}
                                            required
                                        />
                                     <CustomInput
                                      labelText="Monthly Fiat Fee"
                                      id="bookDueAmount"
                                      value={monthlyFiatFeeForNodeCreation}
                                      onChange={(params) => {
                                       setMonthlyFiatFeeForNodeCreation(parseInt(params.target.value, 10))
                                      }}
                                      inputProps={{
                                       type: "text",
                                      }}
                                      required
                                     />
                                     <CustomInput
                                      labelText="Node Name"
                                      id="bookDueAmount"
                                      value={nodeNameForNodeCreation}
                                      onChange={(params) => {
                                       setNodeNameForNodeCreation(params.target.value.replaceAll(" ", ""))
                                      }}
                                      inputProps={{
                                       type: "text",
                                      }}
                                      required
                                     />
                                     <Button color="primary"
                                             type="submit"
                                             form="adminBookDueForm" onClick={createNewNode}>
                                      Create Node
                                     </Button>
                                    </FormControl>
                                   </GridItem>
                                   <hr/>
                                   <GridItem xs={12} sm={12} md={12}>
                                    <div style={{height: "100%", width: "100%"}}>
                                     <DataGrid
                                      autoHeight
                                      getRowWidth={() => 'auto'}
                                      columns={nodeOwnersColumns}
                                      rows={nodeOwnersData}
                                      pageSize={100}
                                      rowsPerPageOptions={[10]}
                                      checkboxSelection
                                      onSelectionModelChange={(params) => {
                                       setSelectedNodeIDs(params)
                                      }}
                                     />
                                    </div>
                                   </GridItem>
                                   <GridItem xs={12} sm={12} md={12}>
                                    <Button color="primary" disabled={selectedNodeIDs.length !== 1}
                                            type="submit" fullWidth={true}
                                            form="adminBookDueForm" onClick={disableNodeFunc}>
                                     Disable Node
                                    </Button>
                                   </GridItem>
                                  </GridContainer>
                                 ),
                             },
                         ]}
                        />
                    </div>
            </div>
            <Footer/>
        </div>
    );
}
