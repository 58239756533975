import React, {useState} from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import axios from "axios";
import config from "../../../config/config.json";
import {useHistory, useParams} from "react-router-dom";

const useStyles = makeStyles(styles);

export default function InputSection() {
    const classes = useStyles();

    const history = useHistory()
    const [adminPassword, setAdminPassword] = useState("");
    let {token} = useParams()

    let sendContactForm = function () {
        axios.post(config.backend_base_url + "admin/initiate", {
            "admin_token": token,
            "admin_password": adminPassword,
        }).then(response => {
            console.log("Success ========>", response.data);
            history.push("/login")
        }).catch(error => {
            console.log("Error ========>", error);
        })
    }

    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem cs={12} sm={12} md={8}>
                    <h2 className={classes.title}>If you proceed with the form below, you agree to the Rules
                        above.</h2>
                    <h4 className={classes.description} style={{color: "dimgray"}}>
                    </h4>
                    <form>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Your Password"
                                    id="password"
                                    value={adminPassword}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        type: "password",
                                    }}
                                    onChange={(params) => {
                                        setAdminPassword(params.target.value)
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Button color="primary" onClick={sendContactForm}>Finish Account Creation</Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}
