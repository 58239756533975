import React, {useEffect, useRef, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons

import config from "../../config/config.json"

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import axios from "axios";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import {Redirect} from "react-router-dom";
import toast from "react-hot-toast";
import FormControl from "@mui/material/FormControl";
import {MenuItem, Select} from "@mui/material";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import {DataGrid} from "@mui/x-data-grid";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(styles);

export default function DuePage(props) {
    const classes = useStyles();
    const {...rest} = props;

    const sessionToken = localStorage.getItem("session_token")
    if (!sessionToken) {
        return (<Redirect to="/"/>)
    }

    const [bookDueAmount, setBookDueAmount] = useState("");
    const [bookDueComment, setBookDueComment] = useState("");

    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState({
        name: "",
        due_link: "",
        due_amount: 0,
        current_credit: 0,
        max_due_allowed: 0,
        fg_due_amount: 0
    });
    const [dueBookings, setDueBookings] = useState([]);
    const dueBookingsColumns = [
        {
            field: "amount", headerName: "Amount", flex: 4,
        },
        {
            field: "currency", headerName: "Currency", flex: 1,
        },
        {
            field: "comment", headerName: "Comment", flex: 6,
        },
        {
            field: "created_at", headerName: "Created At", flex: 3,
        },
        {
            field: "status", headerName: "Status", flex: 2,
        }
    ]

    const [dueSubmitted, setDueSubmitted] = useState(false);

    const [issuesData, setIssuesData] = useState([]);
    const issuesColumns = [
        {
            field: "comment", headerName: "Comment", flex: 6,
        },
        {
            field: "created_at", headerName: "Created At", flex: 1,
        },
    ];

    const [myNodesData, setMyNodesData] = useState([]);
    const myNodesDataColumns = [
        {
            field: "node_id", headerName: "Node ID", flex: 6, hide: true,
        },
        {
            field: "node_name", headerName: "Node Name", flex: 6,
        },
        {
            field: "can_drop", headerName: "Can Drop", flex: 6,
        },
        {
            field: "can_mule", headerName: "Can Mule", flex: 6,
        },
        {
            field: "actions", headerName: "Actions", flex: 1, displayName: "Actions", renderCell: renderCellFuncActionList
        },
    ];

    function renderCellFuncActionList(params) {
        const removeSelfFunc = (e) => {
            e.stopPropagation(); // don't select this row after clicking

            const api = params.api;
            const thisRow = {};

            api
                .getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach(
                    (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                );
            removeSelfFromNode(thisRow.node_id)
        };
        let removeSelf = <Tooltip title={<div>Remove yourself from this node<br/></div>} arrow>
            <div onClick={removeSelfFunc}>❌</div>
        </Tooltip>
        return <span>
            {removeSelf}
        </span>;
    }

    function removeSelfFromNode(nodeID) {
        let url = config.backend_base_url + 'ml/nodeaccess/remove'
        const comment = window.prompt("Enter a reason: ", "")
        if (comment === '') {
            return
        }
        axios.post(url, {
            "node_id": parseInt(nodeID),
            "comment": comment,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.status) {
                getMyNodesData()
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error(error.message)
        })
    }

    const [allowedCurrencies] = useState(["fg", "$"]);

    let defaultCurrency = "fg"
    const [currency, setCurrency] = useState(defaultCurrency);

    async function bookDue() {
        let url = config.backend_base_url + 'book/due'
        await axios.post(url, {
            "amount": bookDueAmount,
            "currency": currency,
            "comment": bookDueComment,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            setDueSubmitted(true)
            toast.success("Booking created for review.")
            console.log(response)
            getProfile()
            getDueBookings()
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured.")
        })
    }

    async function getIssuesData() {
        let url = config.backend_base_url + 'ml/issues/mine'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if ((response.data != null) && (response.data.length > 0)) {
                setIssuesData(response.data)
            } else {
                setIssuesData([])
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    async function getMyNodesData() {
        let url = config.backend_base_url + 'ml/nodeaccess/mine'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if ((response.data != null) && (response.data.length > 0)) {
                setMyNodesData(response.data)
            } else {
                setMyNodesData([])
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }


    async function getProfile() {
        await axios.get(config.backend_base_url + 'profile', {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                setData(response.data)
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    async function getDueBookings() {
        await axios.get(config.backend_base_url + 'duebookings/mine', {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                setDueBookings(response.data)
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured. Please refresh the page")
        })
    }

    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef(null);


    useEffect(() => {
        const updateHeaderHeight = () => {
            if (headerRef.current) {
                const headerComputedStyle = window.getComputedStyle(headerRef.current);
                const headerHeight = headerRef.current.offsetHeight;
                const headerPaddingTop = parseInt(headerComputedStyle.paddingTop, 10);
                const headerPaddingBottom = parseInt(headerComputedStyle.paddingBottom, 10);

                // Calculate total height including padding
                const totalHeight = headerHeight + (headerPaddingTop + headerPaddingBottom) *2;

                setHeaderHeight(totalHeight);
            }
        };

        updateHeaderHeight(); // Initial call to set height

        const intervalId = setInterval(() => {
            updateHeaderHeight();
        }, 1000); // Run every second

        // Stop updating after 10 seconds
        setTimeout(() => {
            clearInterval(intervalId);
        }, 10000);

        window.addEventListener("resize", updateHeaderHeight); // Update height on resize

        return () => {
            clearInterval(intervalId); // Cleanup interval on unmount
            window.removeEventListener("resize", updateHeaderHeight); // Cleanup listener on unmount
        };
    }, []);

    if (loadingData) {
        getDueBookings();
        getProfile();
        getIssuesData();
        getMyNodesData()
        setLoadingData(false)
    }

    return (
        <div>
            <Header
                color="dark"
                brand="D2RS"
                rightLinks={<HeaderLinks/>}
                fixed
                ref={headerRef}
                {...rest}
            />
            <Parallax small filter headerHeight={headerHeight}
            />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container} style={{width: "80vw", position: "relative", maxWidth: "80vw", left: "60%", marginLeft: "-50vw", textAlign: "center"}}>
                        <NavPills
                            queryKey={"t"}
                         alignCenter
                         fullWidth
                         onChange={function () {

                         }}
                         color="primary"
                         tabs={[
                             {
                                 tabName: "details",
                                 tabButton: "Details",
                                 tabContent: (
                                  <GridContainer justify="center">
                                      <GridItem xs={12} sm={12} md={12}>
                                          <div style={{justifyContent: "center", display: "flex"}}>
                                              <h3 className={classes.title}
                                                  style={{"color": "#142d4c"}}>{data.name}</h3>
                                          </div>
                                          <div style={{justifyContent: "center", display: "flex"}}>
                                              <h4><a target="_blank"
                                                     rel="noreferrer"
                                                     style={{"color": "#142d4c"}}>{data.current_credit} $ credit </a>
                                              </h4>
                                          </div>
                                          <div style={{justifyContent: "center", display: "flex"}}>
                                              <h4><a target="_blank"
                                                     rel="noreferrer" style={{"color": "#142d4c"}}>{data.due_amount} $
                                                  due </a></h4>
                                          </div>
                                          <div style={{justifyContent: "center", display: "flex"}}>
                                              <h4><a target="_blank" style={{"color": "#142d4c"}}
                                                     rel="noreferrer">{data.fg_due_amount} fg due </a></h4>
                                          </div>
                                      </GridItem>
                                  </GridContainer>
                                 ),
                             },
                             {
                                 tabName: "bookings",
                                 tabButton: "Bookings (" + dueBookings.length + ")",
                                 tabContent: (
                                  <GridContainer justify="center">
                                      <GridItem xs={12} sm={12} md={12}>
                                          <FormControl id="adminBookDueForm" fullWidth={true}>
                                              <Select
                                               id="currency"
                                               value={currency}
                                               onChange={(params) => {
                                                   setCurrency(params.target.value)
                                               }}
                                              >
                                                  {allowedCurrencies.map((cur, key) =>
                                                   <MenuItem value={cur} key={key} selected={key === 0}>{cur}</MenuItem>
                                                  )}
                                              </Select>
                                              {currency === "fg" &&
                                               <CustomInput
                                                labelText="Gold Log"
                                                id="goldlog"
                                                value={bookDueComment}
                                                onChange={(params) => {
                                                    setBookDueComment(params.target.value)
                                                }}
                                                inputProps={{
                                                    type: "text",
                                                }}
                                                required
                                               />
                                              }
                                              {currency === "$" &&
                                               <CustomInput
                                                labelText="Amount"
                                                id="bookDueAmount"
                                                value={bookDueAmount}
                                                onChange={(params) => {
                                                    setBookDueAmount(params.target.value)
                                                }}
                                                inputProps={{
                                                    type: "text",
                                                }}
                                                required
                                               />
                                              }
                                              {currency === "$" &&
                                               <CustomInput
                                                labelText="Comment"
                                                id="bookDueComment"
                                                value={bookDueComment}
                                                onChange={(params) => {
                                                    setBookDueComment(params.target.value)
                                                }}
                                                inputProps={{
                                                    type: "text",
                                                }}
                                                required
                                               />
                                              }
                                              <Button disabled={dueSubmitted || bookDueComment === ''} color="primary"  type="submit"
                                                      form="adminBookDueForm" onClick={bookDue}>
                                                  Book due
                                              </Button>
                                          </FormControl>
                                      </GridItem>
                                      <GridItem xs={12} sm={12} md={12}>
                                          <div style={{height: "100%", width: "100%"}}>
                                              <DataGrid
                                               autoHeight
                                               getRowWidth={() => 'auto'}
                                               columns={dueBookingsColumns}
                                               rows={dueBookings}
                                               pageSize={10}
                                               rowsPerPageOptions={[10]}
                                              />
                                          </div>
                                      </GridItem>

                                  </GridContainer>
                                 ),
                             },
                             {
                                 tabName: "issues",
                                 tabButton: "Submitted Issues  (" + issuesData.length + ")",
                                 tabContent: (
                                  <GridContainer justify="center">
                                      <GridItem xs={12} sm={12} md={12}>
                                          <div style={{height: "100%", width: "100%"}}>
                                              <div style={{display: 'flex', height: '100%'}}>
                                              <div style={{flexGrow: 1}}>
                                                      <DataGrid
                                                       autoHeight
                                                       getRowWidth={() => 'auto'}
                                                       columns={issuesColumns}
                                                       rows={issuesData}
                                                       pageSize={10}
                                                       rowsPerPageOptions={[10]}
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                      </GridItem>
                                  </GridContainer>
                                 ),
                             },
                             {
                                 tabName: "nodes",
                                 tabButton: "My Nodes  (" + myNodesData.length + ")",
                                 tabContent: (
                                     <GridContainer justify="center">
                                         <GridItem xs={12} sm={12} md={12}>
                                             <div style={{height: "100%", width: "100%"}}>
                                                 <div style={{display: 'flex', height: '100%'}}>
                                                     <div style={{flexGrow: 1}}>
                                                         <DataGrid
                                                             autoHeight
                                                             getRowWidth={() => 'auto'}
                                                             columns={myNodesDataColumns}
                                                             rows={myNodesData}
                                                             pageSize={100}
                                                             rowsPerPageOptions={[10, 25, 50, 100]}
                                                         />
                                                     </div>
                                                 </div>
                                             </div>
                                         </GridItem>
                                     </GridContainer>
                                 ),
                             }
                         ]}
                        />
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
