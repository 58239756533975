import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {BrowserRouter, Route, Switch} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import Components from "views/Components/Components.js";
import DuePage from "./views/DuePage/DuePage.js";
import NodePage from "./views/NodePage/NodePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import TradePage from "./views/TradePage/TradePage";
import SupplierItemPage from "./views/ItemPage/SupplierItemPage";
import UsersNodePage from "./views/UsersNodePage/UsersNodePage";
import InitiationPage from "./views/InitiationPage/InitiationPage";
import MehlbotSalesHistoryPage from "./views/MehlbotSalesHistoryPage/MehlbotSalesHistoryPage";
import PresetPage from "./views/MehlbotPickitPage/PresetPage";
import SupplierPage from "./views/SupplierPage/SupplierPage";
import DropperPage from "./views/DropperPage/DropperPage";
import SellerHelpPage from "./views/HelpPage/SellerHelpPage";
import PublicSearch from "./views/HelpPage/PublicSearch";
import NewItemPage from "./views/NewItemPage/NewItemPage";
import StaffPage from "./views/StaffPage/StaffPage";
import NewHelpPage from "./views/HelpPage/NewHelpPage";
import {Toaster} from "react-hot-toast";

var hist = createBrowserHistory();

ReactDOM.render(
    <BrowserRouter history={hist}>
        <Toaster
            position="bottom-center"
            reverseOrder={false}
        />
        <Switch>
            <Route path="/todo" component={Components}/>

            {/*<Route path="/rs/hello" component={LandingPage}/>*/}
            <Route path="/rs/initiate/:token" component={InitiationPage}/>

            <Route path="/supplier" component={SupplierPage}/>
            <Route path="/node" component={NodePage}/>
            {/*<Route path="/pickit" component={PickitPage}/> this is the old one for the admins*/}
            {/*<Route path="/pickit" component={HelpPage}/>*/}
            <Route path="/sales" component={MehlbotSalesHistoryPage}/>
            <Route path="/users" component={UsersNodePage}/>
            <Route path="/droppers" component={DropperPage}/>

            {/*<Route path="/so/showroom" component={ShopOwnerPage}/> this is the old one for the admins*/}
            {/*<Route path="/showroom" component={ShowRoomPage}/>*/}

            <Route path="/login" component={LoginPage}/>
            <Route path="/due" component={DuePage}/>
            <Route path="/staff" component={StaffPage}/>
            <Route path="/help" component={NewHelpPage}/>
            <Route path="/sitems" component={SupplierItemPage}/>
            <Route path="/trades" component={TradePage}/>
            <Route path="/help" component={SellerHelpPage}/>
            <Route path="/d2rs" component={PublicSearch}/>
            <Route path="/items" component={NewItemPage}/>

            {/*<Route path="/ml/rs/items" component={MehlbotResellerItemPage}/>*/}
            <Route path="/preset" component={PresetPage}/>
            <Route path="/" component={PublicSearch}/>
        </Switch>
    </BrowserRouter>,
    document.getElementById("root")
);
