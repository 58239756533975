import React, {useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import WelcomeSection from "./Sections/WelcomeSection";
import InputSection from "./Sections/InputSection";
import Parallax from "../../components/Parallax/Parallax";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import axios from "axios";
import config from "../../config/config.json";
import {toast} from "react-hot-toast";
import {useHistory, useParams} from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function InitiationPage(props) {
    const classes = useStyles();
    const {...rest} = props;

    const history = useHistory()
    let {token} = useParams()
    const [adminName, setAdminName] = useState("");
    const [loadingData, setLoadingData] = useState(true);

    let sendTokenCheck = async function () {
        await axios.post(config.backend_base_url + "admin/initiate/validate", {
            "admin_token": token,
        }).then(response => {
            setAdminName(response.data["name"])
            setLoadingData(false)
        }).catch(error => {
            console.log("Error ========>", error)
            toast.error("Error occured.")
            history.push("/")
        })
    }

    if (loadingData) {
        sendTokenCheck()
    }

    return (
        <div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand="D2RS"
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white",
                }}
                {...rest}
            />
            <Parallax filter image={require("assets/img/bg7.jpg").default}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <h1 className={classes.title}>Welcome onboard, {adminName}!</h1>
                            <h4>
                                Only a few steps left. Please read the section below carefully and proceed to set your
                                password if you agree.
                            </h4>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <WelcomeSection/>
                    <InputSection/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
